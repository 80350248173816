import React, { FC, createContext } from 'react'

interface ICity {
  city: string
  domain: string
  cityName: string
}

export const CityContext = createContext<ICity>({} as ICity)

export const CityProvider: FC<{}> = ({ children }) => {
  const city = 'cjz18phan005w0769uau34pof'
  const domain = 'mikafood.ru'
  const cityName = 'Магнитогорск'

  return (
    <CityContext.Provider value={{ city, domain, cityName }}>
      {children}
    </CityContext.Provider>
  )
}
