import { useEffect, useState } from 'react'

const useCurrentStuff = () => {
  const [id, setId] = useState<null | string>(null)

  const getCurrentStuff = () => {
    try {
      const stuffString = localStorage.getItem('stuff')
      return stuffString ? JSON.parse(stuffString).id : null
    } catch (error) {
      console.error(error)
      return null
    }
  }

  useEffect(() => {
    setId(getCurrentStuff())
  }, [])

  return { id }
}

export default useCurrentStuff