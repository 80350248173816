import React, { useContext, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import ym, { YMInitializer } from 'react-yandex-metrika'
import Loader from '../components/Loader/Loader'
import Loadable from 'react-loadable'
import '../styled/styles'
import { useHistory, useLocation } from 'react-router'
import { StuffContext } from './context/stuff'

const Auth = Loadable({
  loader: () => import('../pages/auth'),
  loading: Loader,
  delay: 200
})
const Home = Loadable({
  loader: () => import('../pages/home'),
  loading: Loader,
  delay: 200
})
const Collections = Loadable({
  loader: () => import('../pages/shop/collections/components/List'),
  loading: Loader,
  delay: 200
})
const Customers = Loadable({
  loader: () => import('../pages/shop/customers/components/List'),
  loading: Loader,
  delay: 200
})
const Discounts = Loadable({
  loader: () => import('../pages/shop/discounts/components/List'),
  loading: Loader,
  delay: 200
})
const Orders = Loadable({
  loader: () => import('../pages/shop/orders/components/List'),
  loading: Loader,
  delay: 200
})
const Analytics = Loadable({
  loader: () =>
    import('../pages/shop/analytics/components/Analytics/Analytics'),
  loading: Loader,
  delay: 200
})
const ItemsList = Loadable({
  loader: () => import('../pages/shop/items/list'),
  loading: Loader,
  delay: 200
})
const Shop = Loadable({
  loader: () => import('../pages/shop/shops/index'),
  loading: Loader,
  delay: 200
})
const Pages = Loadable({
  loader: () => import('../pages/shop/pages/pages'),
  loading: Loader,
  delay: 200
})
const Promo = Loadable({
  loader: () => import('../pages/shop/pages/promo'),
  loading: Loader,
  delay: 200
})
const Reviews = Loadable({
  loader: () => import('../pages/shop/pages/reviews'),
  loading: Loader,
  delay: 200
})
const Navigation = Loadable({
  loader: () => import('../pages/shop/pages/navigation'),
  loading: Loader,
  delay: 200
})
const Stuff = Loadable({
  loader: () => import('../pages/shop/stuff/index'),
  loading: Loader,
  delay: 200
})
const Settings = Loadable({
  loader: () => import('../pages/shop/settings/index'),
  loading: Loader,
  delay: 200
})
const Iiko = Loadable({
  loader: () => import('../pages/shop/iiko/index'),
  loading: Loader,
  delay: 200
})
const App = () => {
  const { isAdmin } = useContext(StuffContext)
  const history = useHistory()
  const location = useLocation()
  const stuff = JSON.parse(localStorage.getItem('stuff') || '{}')
  React.useEffect(() => {
    if (!stuff.id && location.pathname !== '/auth') history.push(`/auth`)
    if (stuff.id && location.pathname === '/auth') history.push(`/`)
  }, [localStorage.getItem('stuff')])

  useEffect(() => {
    try {
      history.listen(location => {
        ym('hit', location.pathname)
      })
    } catch (error) {
      console.error(error)
    }
  }, [])

  if (!stuff.id) {
    return (
      <Switch>
        <Route component={Auth} />
      </Switch>
    )
  }
  return (
    <>
      <Switch>
        <Route exact path={`/`} component={Home} />
        {/* CUSTOMERS */}
        <Route exact path={`/shop/customers/:id`} component={Customers} />
        <Route exact path={`/shop/customers`} component={Customers} />
        <Route exact path={`/shop/discounts`} component={Discounts} />
        {/* ORDERS */}
        <Route exact path={`/shop/orders/create`} component={Orders} />
        <Route exact path={`/shop/orders/:id`} component={Orders} />
        <Route exact path={`/shop/orders`} component={Orders} />
        <Route exact path={`/shop/iiko`} component={Iiko} />
        {isAdmin && (
          <>
            {/* COLLECTIONS */}
            <Route
              exact
              path={`/shop/collections/create`}
              component={Collections}
            />
            <Route
              exact
              path={`/shop/collections/:collectionId`}
              component={Collections}
            />
            <Route exact path={`/shop/collections`} component={Collections} />
            {/* DISCOUNTS */}
            <Route
              exact
              path={`/shop/discounts/:discountId`}
              component={Discounts}
            />
            {/* ANALYTICS */}
            <Route exact path={`/shop/analytics`} component={Analytics} />
            {/* ITEMS */}
            <Route exact path={`/shop/items`} component={ItemsList} />

            <Route exact path={`/shop/items/create`} component={ItemsList} />
            <Route exact path={`/shop/items/:id`} component={ItemsList} />
            {/* SHOPS */}
            <Route exact path={`/shops/create`} component={Shop} />
            <Route exact path={`/shops/:id`} component={Shop} />
            <Route exact path={`/shops`} component={Shop} />
            {/* PROMO */}
            <Route exact path={`/shop/pages/promo/create`} component={Promo} />
            <Route exact path={`/shop/pages/promo/:id`} component={Promo} />
            <Route exact path={`/shop/pages/promo`} component={Promo} />
            {/* REVIEWS */}
            <Route
              exact
              path={`/shop/pages/reviews/create`}
              component={Reviews}
            />
            <Route exact path={`/shop/pages/reviews/:id`} component={Reviews} />
            <Route exact path={`/shop/pages/reviews`} component={Reviews} />
            {/* NAVIGATION */}
            <Route
              exact
              path={`/shop/pages/navigation/:slug/create`}
              component={Navigation}
            />
            <Route
              exact
              path={`/shop/pages/navigation/:slug/:id`}
              component={Navigation}
            />
            <Route
              exact
              path={`/shop/pages/navigation/:slug`}
              component={Navigation}
            />
            {/* PAGES */}
            <Route exact path={`/shop/pages/pages/create`} component={Pages} />
            <Route exact path={`/shop/pages/pages/:id`} component={Pages} />
            <Route exact path={`/shop/pages/pages`} component={Pages} />
            {/* STUFF */}
            <Route exact path={`/shop/stuff/create`} component={Stuff} />
            <Route exact path={`/shop/stuff/:id`} component={Stuff} />
            <Route exact path={`/shop/stuff`} component={Stuff} />
            {/* SETTINGS */}
            <Route exact path={`/shop/settings`} component={Settings} />
          </>
        )}
      </Switch>
      <YMInitializer
        accounts={[61891212]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
          trackHash: true
        }}
        version="2"
      />
    </>
  )
}
export default App
