export const REFERENCE_VARIANT_ID = 'ck4e8l4rd04f10769s65pdydg'

export const GREEN = '#78B833'
export const BLACK = '#000000'
export const GRAY_1 = '#F6F9FC'
export const GRAY_3 = '#DEE4EC'
export const GRAY_5 = '#808080'

export const EASE_IN_TIME = 150
export const EASE_OUT_TIME = 200
export const easeInCss = `transition: ${EASE_IN_TIME}ms ease-in;`
export const easeOutCss = `transition: ${EASE_OUT_TIME}ms ease-out`
