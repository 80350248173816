import { injectGlobal } from 'styled-components'
import baseStyles from '../components/@atlaskit/css-reset/base'
import browserFixesStyles from '@atlaskit/css-reset/browser-fixes'
import resetStyles from '@atlaskit/css-reset/reset'
import tableStyles from '@atlaskit/css-reset/tables'
import utilStyles from '@atlaskit/css-reset/utils'

injectGlobal`
${resetStyles}
${baseStyles}
${tableStyles}
${browserFixesStyles}
${utilStyles}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

textarea:focus,
input:focus,
button:focus,
select:focus {
  outline: none;
}

body {
  font: 0.8125rem Soleil,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Helvetica,Arial,"Open Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  color: #3d556b;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

hr {
  margin: 30px 0;
  border: none;
  border-top: 1px solid #e7e7e9;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// div, form, a {
//   box-sizing: border-box;
// }
//
// a, a:hover {
//   color: #3d556b;
//   text-decoration: none;
//   cursor: pointer;
// }
//
// h1, h2, h3, h4, h5, h6 {
//   font-weight: 600;
// }
//
// pre {
//   font-family: 'Source Code Pro', monospace;
// }
`
