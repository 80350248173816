import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'

export const token = `${process.env.REACT_APP_BINDING_TOKEN}`
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BINDING_ENDPOINT}`
})
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})
export const getApolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})
export default async function query(
  gql,
  variables,
  changeClient: any = undefined
) {
  return await getApolloClient.query({ query: gql, variables })
}
export async function mutate(mutation, variables, refetchQueries = undefined) {
  return await getApolloClient.mutate({ mutation, variables, refetchQueries })
}
