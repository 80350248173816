import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { ApolloProvider } from 'react-apollo'
import { getApolloClient } from './components/Apollo'
import { NotificationProvider } from './components/context/notification'
import { ReviewNotificationProvider } from './components/context/review-notification'
import { StuffProvider } from './components/context/stuff'
import { CityProvider } from './components/context/city'

const WrappedApp = () => {
  return (
    <ApolloProvider client={getApolloClient}>
      <CityProvider>
        <StuffProvider>
          <ReviewNotificationProvider>
            <NotificationProvider>
              <Router>
                <App />
              </Router>
            </NotificationProvider>
          </ReviewNotificationProvider>
        </StuffProvider>
      </CityProvider>
    </ApolloProvider>
  )
}

ReactDOM.render(
  <WrappedApp />,
  document.getElementById('react-content') as HTMLElement
)
serviceWorker.unregister()
