import React from 'react'
import Spinner from '@atlaskit/spinner'
import { StyledLoader, StyledLoaderWrapper } from './styles'

const Loader = () => (
  <StyledLoaderWrapper>
    <StyledLoader>
      <Spinner size="large" />
    </StyledLoader>
  </StyledLoaderWrapper>
)
export default Loader
