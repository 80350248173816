import React, { FC, createContext, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { GREEN, easeOutCss, easeInCss } from '../contants'
import ClosedIcon from '../../icons/close.svg'

type tType = 'ok' | 'error' | 'warning'

interface INotification {
  setMessage: (message: string) => void
  setType: (typeT: tType) => void
  setShow: (show: boolean) => void
}

function getColor(type: tType) {
  switch (type) {
    case 'ok':
      return GREEN
    case 'error':
      return 'red'
    case 'warning':
      return 'yellow'
  }
}

export const NotificationContext = createContext<INotification>(
  {} as INotification
)
export const NotificationProvider: FC<{}> = ({ children }) => {
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState()
  const [type, setType] = useState<tType>('ok')

  const timer: any = useRef()

  useEffect(() => {
    if (!message) {
      return
    }
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      setShow(false)
    }, 2500)
  }, [message, show])

  return (
    <NotificationContext.Provider
      value={{
        setMessage: setMessage as any,
        setType,
        setShow
      }}
    >
      <Wrapper>
        <Box type={type} show={show}>
          <span>{message}</span>
          <Close onClick={() => setShow(false)} />
        </Box>
      </Wrapper>
      {children}
    </NotificationContext.Provider>
  )
}

const Wrapper = styled.div`
  z-index: 1000;
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  text-align: center;
  height: 0;
`

interface IBox {
  type: tType
  show: boolean
}
const Box = styled.span<IBox>`
  display: inline-flex;
  align-items: center;
  padding: 10px;
  color: #fff;
  background: ${({ type }) => getColor(type)};
  box-shadow: 0px 12px 44px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  ${({ show }) => (show ? easeOutCss : easeInCss)}
  opacity: ${({ show }) => (show ? 1 : 0)};
  transform: ${({ show }) =>
    show ? 'translate3d(0, 0, 0)' : 'translate3d(0, -10px, 0)'};
`

const Close = styled.span`
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 0.5em;
  background: url(${ClosedIcon}) no-repeat center;
  background-size: 12px;
`
