import React, { FC, createContext, useContext } from 'react'
import { useQuery } from 'react-apollo'
import moment from 'moment'
import gql from 'graphql-tag'
import { useLocalStorage } from '../../utils/useLocalStorage'
import { CityContext } from './city'

interface IReviewNotification {}

export const ReviewNotificationContext = createContext<IReviewNotification>(
  {} as IReviewNotification
)

const REVIEWS_LIST = gql`
  query reviewsConnection($where: ReviewWhereInput) {
    reviewsConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const ReviewNotificationProvider: FC<{}> = ({ children }) => {
  const today = new Date().toISOString()
  const [whenSawReviewsLastTime, setWhenSawReviewsLastTime] = useLocalStorage(
    'whenSawReviewsLastTime',
    today
  )

  const { domain } = useContext(CityContext)

  const { data } = useQuery(REVIEWS_LIST, {
    variables: {
      where: {
        createdAt_gt: whenSawReviewsLastTime,
        shop: { primaryDomain: { host: domain } }
      }
    },
    pollInterval: 30000
  })

  const newReviewsCount = data && data.reviewsConnection.aggregate.count

  const reviewData = [newReviewsCount, setWhenSawReviewsLastTime]

  return (
    <ReviewNotificationContext.Provider value={reviewData}>
      {children}
    </ReviewNotificationContext.Provider>
  )
}
