import React, {
  FC,
  createContext,
  useState,
  useEffect,
  useContext
} from 'react'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo'
import Loader from '../Loader/Loader'
import getUndef from '../../utils/get-undef'
import { CityContext } from './city'
import useCurrentStuff from '../hooks/useCurrentStuff'

const STUFF = gql`
  query stuff($id: ID!, $domain: String!) {
    stuff(where: { id: $id }) {
      id
      type
      login
      shops(where: { name_not: "Доставка", primaryDomain: { host: $domain } }) {
        id
        name
      }
    }
  }
`

interface IStuff {
  type: string
  stuff: any
  isAdmin: boolean
  shops: Array<string>
}

export const StuffContext = createContext<IStuff>({} as IStuff)
export const StuffProvider: FC<{}> = ({ children }) => {
  const { domain } = useContext(CityContext)
  const { id } = useCurrentStuff()

  const { data, loading } = useQuery(STUFF, {
    skip: !id,
    variables: {
      id,
      domain
    },
    onError: err => console.error(err)
  })

  if (loading) {
    return <Loader />
  }

  const stuff = getUndef(() => data.stuff)
  const type = getUndef(() => stuff.type)
  const isAdmin = type === 'ADMIN'
  const shops = getUndef(() => stuff.shops.map(({ id }) => id)) || []

  return (
    <StuffContext.Provider
      value={{
        type,
        isAdmin,
        shops,
        stuff
      }}
    >
      {children}
    </StuffContext.Provider>
  )
}
